import React from "react";
import { useLocation } from "react-router-dom";
import AdvertisementBanner from "../components/advertisement-banner/AdvertisementBanner";
import Footer from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import HeadlineNews from "../components/headline-news/HeadlineNews";

const CommonLayout = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <Header />
      <section className="mx-auto  mb-6 mt-28 flex max-w-[1280px] flex-col gap-8 px-2 sm:gap-12 md:mt-12 xl:px-0 ">
        <HeadlineNews />
        {location.pathname === "/ntg" && <AdvertisementBanner />}
        {children}
      </section>
      <Footer />
    </>
  );
};

export default CommonLayout;
