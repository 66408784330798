import React from "react";

const HorizontalLine = () => {
  return (
    <div className="w-full  h-[5px] md:h-[7px] flex" title="Sagar aka Reyzon">
      <p className="w-[35%] bg-red-800  h-full" />
      <p className="w-[65%] bg-zinc-300  h-full" />
    </div>
  );
};

export default HorizontalLine;
