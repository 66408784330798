import React from "react";
import AddCompany from "./AddCompany";

const Company = () => {
  return (
    <div className=''>
      <AddCompany />
    </div>
  );
};

export default Company;
