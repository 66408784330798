import { motion } from "framer-motion";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getCompany } from "../../../store/company/companyAction";
import { IIMAGE_URL } from "../../../utils/constants";
import DropDown from "./DropDown";

const TOPBAR_HEIGHT = 950; // Height before navbar becomes fixed

const Navbar = ({ isOpen, toggleMenu }) => {
  const location = useLocation();
  const [overflow, setOverflow] = useState(true);
  const { categories, error } = useSelector((state) => state.category);
  const [sliceValue, setSliceValue] = useState(9);
  const remainingCategories = categories?.slice(sliceValue);
  const [isFixed, setIsFixed] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company } = useSelector((state) => state.company);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setIsFixed(currentScrollY > TOPBAR_HEIGHT);
  }, []);

  useEffect(() => {
    dispatch(getCompany());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setSliceValue(window.innerWidth < 1200 ? 6 : 8);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const image = company?.fileupload?.name;
  const companyName =
    company?.name?.split(/\s+/).filter((part) => part.trim() !== "") || [];
  const firstName = companyName[0] || "खुराक";
  const secondName = companyName[1] || "मिडिया";

  // Check if the viewport is desktop-sized
  const isDesktop = window.innerWidth >= 768;

  return (
    <>
      {/* Placeholder div to prevent layout shift when navbar becomes fixed */}
      {isDesktop && isFixed && <div className="h-[54px] w-full" />}
      <motion.nav
        className={`z-40 mx-auto mt-4 bg-navbarBgColor text-navTextColor md:mt-0 md:h-[58px]
          ${overflow && "overflow-hidden"}
          ${
            isFixed && isDesktop
              ? "left-0 right-0 top-0 w-full shadow-lg transition-shadow duration-150 ease-in md:fixed md:top-0"
              : "relative xl:w-[1280px] xl:rounded-md"
          }
        `}
      >
        <div
          className={`flex scale-y-100 flex-col items-center justify-around px-4 opacity-100 md:flex-row 
          ${
            isOpen
              ? "h-[480px] scale-y-100 py-3 opacity-100 md:h-auto md:py-0"
              : "h-0 scale-y-0 overflow-hidden p-0 opacity-0 md:h-auto md:scale-y-100 md:overflow-visible md:opacity-100"
          }`}
        >
          <motion.section
            initial={{ opacity: 0 }}
            animate={{
              opacity: isFixed ? 1 : 0,
              transition: { duration: 0.2 },
            }}
            className={`${isFixed ? "block" : "hidden"}`}
          >
            {image ? (
              <img
                src={`${IIMAGE_URL}/${image}`}
                loading="lazy"
                className="hidden h-full w-12 cursor-pointer transition-transform hover:scale-105 md:block"
                onClick={() => navigate("/")}
                alt="logo"
              />
            ) : (
              <figcaption className="h-16 w-16 cursor-pointer rounded-full bg-gray-300 transition-transform hover:scale-105 md:h-24 md:w-24"></figcaption>
            )}
          </motion.section>

          <NavLink
            onClick={toggleMenu}
            to="/"
            className={`w-full cursor-pointer rounded-lg px-4 py-2 text-navTextColor transition-all duration-200 hover:bg-navbarBgColor hover:brightness-125 md:w-auto md:rounded-none md:py-4 md:font-semibold lg:text-[16px]
            ${"/" === decodeURIComponent(location.pathname) ? "bg-red-600" : ""}`}
          >
            Inicio
          </NavLink>

          {categories?.slice(0, sliceValue).map((cat, i) => (
            <NavLink
              onClick={toggleMenu}
              to={decodeURIComponent(cat?.slug)}
              key={i}
              className={`w-full cursor-pointer rounded-lg px-4 py-2 text-navTextColor transition-all duration-200 hover:bg-navbarBgColor hover:brightness-125 md:w-auto md:rounded-none md:py-4 md:font-semibold lg:text-[16px] 
              ${
                `/${cat.name}` === decodeURIComponent(location.pathname)
                  ? "bg-red-600"
                  : ""
              }`}
            >
              {cat.name}
            </NavLink>
          ))}

          {error && (
            <NavLink className="md:self-center">
              Error fetching categories
            </NavLink>
          )}

          <DropDown
            toggleDropdown={() => setOverflow(false)}
            remainingCategories={remainingCategories}
            toggleMenu={toggleMenu}
          />
        </div>
      </motion.nav>
    </>
  );
};

export default Navbar;
